import { useContext, useEffect, useState } from 'react';
import { useExperiments } from '@wix/fe-essentials-standalone';
import { EXPERIMENTS, LOCALES } from '../constants';
import { useRouter } from 'next/router';
import { Context } from '../context';
import { useWindowSize } from './useWindowSize';
import { isMobile, isTablet } from '../utils/userAgent';

export const useIsGenAnswer = () => {
  const { experiments } = useExperiments({ readOnly: true });
  const context = useContext(Context);
  const [isMobileView, setIsMobileView] = useState(context.isMobile);
  const [isTabletView, setIsTabletView] = useState(context.isTablet);
  const windowSize = useWindowSize();

  useEffect(() => {
    if (windowSize.width) {
      setIsMobileView(isMobile(context));
      setIsTabletView(isTablet(context));
    }
  }, [context, windowSize.width]);

  const { locale } = useRouter();
  const isGenAnswerEnabled =
    experiments.enabled(EXPERIMENTS.SPECS.NEXT_GEN_SEARCH) &&
    locale === LOCALES.EN &&
    !isMobileView &&
    !isTabletView;

  return isGenAnswerEnabled;
};
