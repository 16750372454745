import { createContext, useContext } from 'react';
import { createStore, useStore as useZustandStore } from 'zustand';

import { BISlice, getBIInitialState } from './bi';
import { CategoriesTreeSlice, getCategoriesTreeInitialState } from './categories-tree';

export type Store = {
  categoriesTreeSlice: CategoriesTreeSlice;
  biSlice: BISlice;
};

export type StoreType = ReturnType<typeof initializeStore>;

const storeContext = createContext<StoreType | null>(null);

export const Provider = storeContext.Provider;

export const useStore = <T>(selector: (state: Store) => T): T => {
  const store = useContext(storeContext);
  if (!store) {
    throw new Error('Store is missing the provider');
  }
  return useZustandStore(store, selector);
};

export const getInitialState = (
  preloadedState: Partial<Store>,
  set: (stateUpdater: (state: Store) => Partial<Store>) => void
) => ({
  categoriesTreeSlice: {
    ...getCategoriesTreeInitialState(),
    ...(preloadedState?.categoriesTreeSlice),
  },
  biSlice: {
    ...getBIInitialState(set),
    ...(preloadedState?.biSlice),
  }
});

export const initializeStore = (preloadedState: Partial<Store>) =>
  createStore<Store>()((set) => getInitialState(preloadedState, set));
